.plan-main-ctr {
    background-color: var(--background-color-500);
    height: auto;
    width: 100%;
    overflow: scroll;
}

.pricing-holder {
    margin-bottom: 20vh;
    align-self: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* background-color: aquamarine; */
}

.all-pricing-header {
    width: 100% !important;
    margin-top: 30px;
    color: var(--text-color-100);
}

.spin-loading {
    margin-right: 10px;
}

.ant-spin-dot-item {
    background-color: var(--background-color-100) !important;
}

/* Mobile Devices */
@media only screen and (max-width: 767px) {
    .card_holder {
        display: flex;
        flex-direction: column !important;
        top: 75%;
        position: relative;
    }

    .mobile-card-ctr {
        margin-bottom: 30px !important;
    }

    .plan-main-ctr {
        height: 100% ;
    }
}

/* Tablets: */
@media only screen and (min-width: 768px) and (max-width: 991px) {
}

/* Small Laptops and Desktops */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
}

/* Standard Laptops and Desktops: */
@media only screen and (min-width: 1200px) {
}