:root {
  /* you can get the colors from https://colors.eva.design/ */
  --tab-Hover-color: #CDEFFB;
  
  /* Border Colors */
  --border-color-100: #ebf5fb;
  --border-color-200: #9CDCF8;
  --border-color-300: #69BCEB;
  --border-color-400: #429AD7;
  --border-color-500: #3f5885;

  /* Background Colors Below */
  --background-white: #ffff;
  --background-color-100: #ebf5fb;
  --background-color-200: #9CDCF8;
  --background-color-300: #69BCEB;
  --background-color-400: #429AD7;
  --background-color-500: #7e98b9;
  --background-color-600: #0A53A2;
  --background-color-700: #073E88;
  --background-color-800: #042C6D;
  --background-color-900: #3f5885;

  --card-background-color: #ebf5fb;

  /* text color */
    --text-color-white: #ffffff;
    --text-color-black: #000000;
    --text-color-gray: #666666;
    --text-color-100: #ebf5fb; 
    --text-color-200: #9CDCF8;
    --text-color-300: #69BCEB;
    --text-color-400: #429AD7;
    --text-color-500: #3f5885;
    --text-color-600: #0A53A2;
    --text-color-700: #073E88;
    --text-color-800: #042C6D;
    --text-color-900: #021F5A;
  
    /* danger Color */
    --danger-color-400: #FF935C;
    --danger-color-500: #FF6026;
    --danger-color-600: #DB411B;
/* Success Color */
  --color-success-100: #DBFCD6;
  --color-success-200: #B2F9AE;
  --color-success-300: #83EF89;
  --color-success-400: #62E075;
  --color-success-500: #33CC59;
  /* Box Shadow */
  --shadow-primary: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

html, body {
  height: 100vh;
  margin: 0;
  padding: 0;
  /* overflow: hidden; */
}

* {
  font-family: 'Lato', sans-serif;
  margin: 0;
}

/* For webkit-based browsers like Chrome and Safari */
/* Customize the track */
::-webkit-scrollbar {
  width: 7px;
  border-radius: 5px;
}

/* Customize the thumb (the draggable part of the scrollbar) */
::-webkit-scrollbar-thumb {
  background: var(--background-color-300); /* Change the color of the thumb */
  border-radius: 5px;
}

/* Customize the track on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--background-color-400);
}


/* Mobile Devices */
@media only screen and (max-width: 767px) {
}

/* Tablets: */
@media only screen and (min-width: 768px) and (max-width: 991px) {
}

/* Small Laptops and Desktops */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
}

/* Standard Laptops and Desktops: */
@media only screen and (min-width: 1200px) {
}
