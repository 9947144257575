.go-back-ctr {
    display: none;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    color: var(--text-color-100);
    font-size: 1.2rem;
    position: absolute;
    background-color: var(--background-color-900);
    padding: 5px 10px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

    top: 20px;
    left: 20px;

    .icon {
        margin-right: 10px;
    }

    p {
        margin: 0;
        margin-left: 10px;
        font-size: 1.3rem;
        font-weight: 600;
        text-transform: uppercase;
    }
}

/* Mobile Devices */
@media only screen and (max-width: 767px) {
    .go-back-ctr {
        display: flex;
    }
}

/* Tablets: */
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .go-back-ctr {
        display: flex;
    }
}

/* Small Laptops and Desktops */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
}

/* Standard Laptops and Desktops: */
@media only screen and (min-width: 1200px) {
}