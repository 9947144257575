.videos-ctr {
    min-height: 100vh;
    width: 100%;
    /* background-color: #7E98B9; */
    background: linear-gradient(to bottom, #7E98B9, #FCECED);
    /* background: radial-gradient(ellipse at center, #FEECEA, #6D7D9E); */
    /* background: radial-gradient(circle, #FAEAEB, #232F61); */
    padding: 10vh;
}

.video-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: minmax(100px, auto); /* Set a specific height for each row */
  /* gap: 20px; */
  gap: 80px;
  padding: 20px;
}

.grid-item {
  /* border: 1px solid #ccc; */
  /* padding: 20px; */
  text-align: center;
  width: 100%;
  height: auto;
  /* min-height: 100px; */
}

.grid-item iframe {
    width: 99%;
    aspect-ratio: 16 / 9;
}

.grid-item h3 {
    color: #3f5885;
    margin: 10px 0;
}

.grid-item p {
    /* color: #3f5885; */
    margin: 10px 0;
    text-align: justify;
}

.video-header {
    width: 100%;
}

@media only screen and (max-width: 600px) {
    .videos-ctr {
        padding: 0;
        padding: 10vh 0;
    }
    .video-grid {
        grid-template-columns: repeat(1, 1fr);
    }
}