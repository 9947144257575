.login-ctr {
  /* background: linear-gradient(to bottom, var(--background-white), var(--background-color-200));width: 100%; */
  background-color: #7e98b9;
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 10vh 0; */
}

.register-box {
  margin: 10vh 0;
}

.login-box {
  width: 45%;
  background-color: var(--card-background-color);
  min-height: 40vh;
  padding: 50px 0;
  border-radius: 20px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px;

  .login_title {
    margin: 0;
    color: var(--text-color-500);
    /* font-weight: 700; */
    text-align: center;
    width: 100%;
    font-weight: 900;
    text-shadow: 2px 0 0 rgba(0, 0, 0, 0.5);
    font-size: 2.3rem;
  }

  .login_subtitle {
    color: var(--text-color-500);
    margin: 5px 0 20px 0;
    text-align: center;
    /* color: var(--text-color-gray); */
    max-width: 70%;
  }
}

.ant-input {
  /* background-color: white; */
  border-color: #24a9db;
}

.ant-input-password {
  border-color: #24a9db;
}

.login-form_ctr {
  width: 50%;

  .form-item {
    width: 100%;
    padding: 10px 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .item_title {
    color: var(--text-color-500);
    margin: 0 0 0 5px;
    /* letter-spacing: 1px; */
    font-weight: 600;
  }

  .forgot_password {
    /* background-color: #24A9DB; */
    text-align: right;
    /* margin: 0; */
    margin-top: -25px;
    margin-bottom: 30px;
    font-size: small;
    font-weight: 500;
    color: var(--text-color-500);
    font-weight: 700;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .ant-btn {
    background-color: var(--background-color-900);
    transition: all 0.2s ease-in-out;
    color: var(--text-color-100) !important;
    letter-spacing: 1px;
    font-weight: 700;
    padding: 20px 20px !important;
    /* margin-bottom: 5px; */
    border: none;

    &:hover {
      background-color: var(--background-color-900);
      color: var(--text-color-white) !important;
      
    }
  }

  /* .ant-btn span:hover {
                    color: white;
    */
}
.signup-text {
  margin: 0;
  margin-top: -15px;
}

.signup-text span {
  margin-left: 5px;
  font-weight: bold;
  color: var(--text-color-500);
  /* text-decoration: underline; */

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}
.tradingview_ctr {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 30px;

  .img {
    height: 30px;
  }

  .link {
    text-decoration: none;
    color: var(--text-color-400);
    transition: all 0s ease-in;
    margin-left: 2px;

    &:hover {
      cursor: pointer;
    }
  }
}

.verified-ctr {
  display: flex;
  flex-direction: column;
  /* background-color: aquamarine; */
  justify-content: center;
  align-items: center;
  height: 50vh;
}

.verified-img {
  height: 180px;
}

.verified-text {
  width: 40%;
  text-align: center;
  font-size: medium;
}

.verified-text span {
  font-weight: bold;
  cursor: pointer;
  color: var(--text-color-500);
  text-decoration: underline;
}

.notification-title {
  color: var(--text-color-500);
  font-weight: 600;
}

.success {
  color: var(--color-success-500) !important;
}

.error {
  color: var(--danger-color-500) !important;
}

/* Mobile Devices */
@media only screen and (max-width: 767px) {
  .login-box {
    width: 100%;
    height: 100vh;
    border-radius: 0;
    font-size: 1rem;
  }

  .login_title {
    font-size: 2.5rem !important;
  }

  .login-form_ctr {
    width: 90%;
  }

  .register-box {
    margin: 0;
  }
}

/* Tablets: */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .login-box {
    width: 80%;
  }
}

/* Small Laptops and Desktops */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .login-box {
    width: 60%;
  }
}

/* Standard Laptops and Desktops: */
@media only screen and (min-width: 1200px) {

}
