.event-ctr {
    height: 100vh;
}

.event_calendar-ctr {
    width: 99.9%;
    /* border: 1px solid var(--border-color-300); */
    /* border-radius: 10px; */
    height: calc(100vh - 8vh);
    overflow-y: scroll;
}

.date-cell {
    height: 100px;
    width: 90%;
    background-color: var(--background-color-100);
    text-align: right;
    border-radius: 5px;
    overflow-y: scroll;

    padding: 0;
    transition: all 0.1s ease-in-out;

    &:hover {
        scale: 1.1;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        background-color: var(--background-color-200);
    }
}

.today {
    border: 2px solid var(--border-color-400);
    box-shadow: var(--background-color-200) 0px 3px 8px;
}

.date_cell_list-ctr {
    display: flex;
    flex-direction: column;
    padding: 0 5px 10px 10px;
    text-align: left;
    font-size: 0.7rem;

    .ant-badge-status-text {
        font-size: 0.7rem !important;
        line-height: 0 !important;
    }
}

.date-cell p {
    margin-right: 5px;
}

.event-calendar {
    height: 100vh !important;
}

.event_modal {
    width: 90% !important;
}

.calander_filter_modal {
    width: 50% !important;
}

.calander_form {
    margin-top: 30px;
}

.show_more {
    text-align: right;

    &:hover {
        text-decoration: underline;
    }
}

.ant-card-head-title {
    text-align: center;
}

.hide {
    display: none;
}

.my-year-select {
    width: 100px;
}
.my-year-select:first-child {
    margin-right: 20px;
    margin-left: auto;
}

.body_text {
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    display: -webkit-box;
}

.badge {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.dot {
    height: 6px;
    aspect-ratio: 1;
    border-radius: 10px;
    margin-right: 5px;
}

.calendar_filter_icon {
    font-size: 1.5rem;
    margin: 0 20px;
    transition: all 0.1s ease-in;

    &:hover {
        cursor: pointer;
        scale: 1.1;
    }

    &:active {
        scale: 1;
    }
}

.header_row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.card_header_title {
    position: relative;
    height: 50px;
    /* background-color: antiquewhite; */
    align-items: center;
    display: flex;
    justify-content: center;


    .select_btn {
        position: absolute;
        right: 10px;
    }
}

.spinner_ctr {
    display: flex;
    flex-direction: row;
    align-items: center;

    .text_loading {
        margin-left: 10px;
    }
}
/* .filter_form_buttons-ctr {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .form-item {
        margin: 0;
    }

    .form-buttons {
        width: 100px;

    }

    .form-buttons:nth-child(1) {
        margin-left: 20px;
    }
} */
