.container {
  /* background: linear-gradient(to bottom, var(--background-white), var(--background-color-200));width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    overflow: scroll; */

  /* New background image property */

  /* height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* overflow-y: scroll; */
  width: 100%;
}

.homescreen {
  height: 120vh;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.homescreen::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 20%; /* Adjust the height of the gradient fade effect */
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), #7e98b9);
  pointer-events: none; /* Ensure that the pseudo-element doesn't interfere with interactions */
}

.homescreen {
  /* Other styles for your homescreen */
  background-image: url('./Assets/background.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.loading {
  filter: blur(10px);
  clip-path: inset(0);
}

.loaded {
  filter: blur(0px);
  transition: filter 0.5s linear;
}

.home_content-ctr {
  /* background-color: aqua; */
  position: absolute;
  top: 85%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;

  z-index: 500;

  .header {
    font-weight: 900;
    letter-spacing: 0.5px;
  }

  .header-sub {
    font-weight: 800;
    letter-spacing: 1px;
    margin-top: 10px;
  }

  .header-btn {
    text-decoration: none;
    margin-top: 40px !important;
    padding: 10px 35px;
    border-radius: 25px;
    border: none;
    box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px,
      rgba(6, 24, 44, 0.65) 0px 4px 6px -1px,
      rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
    background-color: white;
    font-weight: 600;
    font-size: 1.2rem;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    color: black;
    position: absolute;
    left: 0;
    right: 0;
    top: 40px;
    margin: 0 auto;
    width: fit-content;

    &:hover {
      scale: 1.05;
    }

    &:active {
      scale: 1;
    }
  }
}

.section-ctr {
  width: 100%;
  max-height: 500vh;
}

.btn-ham {
  background: dodgerblue;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* .center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  width: 100px;
  z-index: 1000;
}

.center:before,
.center:after,
.center div {
  background: red;
  content: "";
  display: block;
  height: 6px;
  border-radius: 3px;
  margin: 7px 0;
  transition: 0.5s;
}
.center:hover:before {
  transform: translateY(12px) rotate(135deg);
}
.center:hover:after {
  transform: translateY(-12px) rotate(-135deg);
}
.center:hover div {
  transform: scale(0);
} */

/* Extra Small Devices (phones) */
@media only screen and (max-width: 600px) {
  /* Your styles for extra small devices go here */
  .container {
    overflow-x: hidden;
  }

  .homescreen {
    background-position: -625px center;
    overflow-x: hidden;
  }

  .home_content-ctr {
    width: 90%;
  }
}
