.privacypolicy {
    width: 80% !important;
    font-size: 1.2rem;
    letter-spacing: 1px;
}

.privacypolicy-ctr {
    background-color: #ebf5fb ;
    color: #3f5885;
    padding: 0 10%;
    text-align: justify;
    padding-bottom: 5%;
}

.sub-header-modal {
    font-weight: 800;
    font-size: 1.3rem;
    margin: 25px 0 5px 0;
    color: #3f5885;
}

.end-text {
    text-align: center;
    margin-top: 30px;
    letter-spacing: 1px;
}

.policy-header {
    width: 100%;
    color: #3f5885;
    padding: 25px 0;
    text-align: center;
    width: 100% !important;
}

.footer-btn {
    /* align-self: flex-start; */
  font-size: 1.15rem;
  padding: 10px 15px;
  border-radius: 5px;
  border: none;
  background-color: #3f5885;
  margin-top: 10px;
  color: white;
  cursor: pointer;
  text-decoration: none;
}

@media only screen and (max-width: 600px) {
    .privacypolicy {
        width: 100% !important;
        /* font-size: 1.2rem;
        letter-spacing: 1px; */
    }

    .sub-header-modal {
        font-size: 1rem;
    }
}