.planet_content {
    display: flex;
    flex-direction: row;
}

.chart_ctr {
    flex: 1;
    margin-left: 7px;
}

.planet_chart {
    /* margin: 0 30px; */
    width: 100%;
    height: 70%;
    border-radius: 7px;
    overflow: hidden;
    box-shadow: var(--shadow-primary);
}

.planet_chart-line {
    width: 100%;
    height: 30%;
    border-radius: 7px;
    overflow: hidden;
    box-shadow: var(--shadow-primary);
}

.right_secttion {
    /* background-color: red; */
    background-color: var(--background-white);
    width: 28.5%;
    margin: 0 7px;
    box-shadow: var(--shadow-primary);
    border-radius: 5px;
    overflow-y: hidden;
}