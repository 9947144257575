.footer-ctr {
  /* min-height: 100vh; */
  background-color: #fceced;
  color: #3f5885;
  width: 100%;
  /* background: radial-gradient(ellipse at center, #FEECEA, #6D7D9E); */
  /* background: radial-gradient(circle, #FAEAEB, #232F61); */
  padding: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section-ctr {
  /* background-color: blue; */
  display: flex;
  flex-direction: row;
}

.left {
  width: 50%;
  .img-logo {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .logo-text {
    margin-left: 20px;
  }
  .logo-text h2 {
    text-transform: uppercase;
  }
  .logo-text p {
    width: 60%;
    font-size: 0.9rem;
  }
}

.right {
  /* background-color: aquamarine; */
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  .url_2 {
    display: flex;
    flex-direction: column;
  }

  .footerlinks {
    margin: 0 0 15px 0;
    cursor: pointer;
    font-weight: 600;
    letter-spacing: 1px;
    color: #3f5885;
    text-decoration: none;
  }
}

.left p {
  width: 35%;
  margin-top: 10px;
  font-weight: 600;
  letter-spacing: 1px;
}

.divider {
  height: 2px;
  background-color: #3f5885;
  width: 100%;
  align-self: center;
  margin: 20px 0;
}

/* 0 - 600px Screen Styles */
@media only screen and (max-width: 600px) {
  /* Your styles for screens up to 600px go here */
  .footer-ctr {
    padding: 10px 0;
  }

  .section-ctr {
    flex-direction: column;
  }

  .left {
    width: 100%;
    margin-bottom: 20px;
  }

  .left p {
    width: 100%;
  }

  .right {
    width: 100%;
  }

  .divider {
    width: 90%;
    align-self: center;
    justify-self: center;
    /* margin: 0; */
  }

  .copyright {
    font-size: 0.8rem;
  }
}
