.error_page-ctr {
    background-color: var(--background-color-100);
    height: 100vh;
    display: flex;
    flex-direction: row;

    .left-content {
        /* background-color: azure; */
        width: 30%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;


        .error-code {
            font-size: 7rem;
            margin: 0;
            color: var(--text-color-500);
        }

        .error-text {
            font-size: 1.5rem;
            margin: 0;
            margin-top: -20px;
            color: var(--text-color-300);
        }

        .error-btn {
            margin-top: 40px;
            width: 50%;
            letter-spacing: 1px;
            font-weight: 400;
            color: var(--text-color-white);
            background-color: var(--background-color-500);

            &:hover {
                color: var(--text-color-white);
            }
        }

        /* flex: 1 */
    }

    .right-content {
        /* background-color: antiquewhite; */
        flex: 1;

        .error_image {
            height: 100vh;
        }
    }
}