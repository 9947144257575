.benefits-ctr {
  padding-top: 10vh;
  /* Remove or set height to auto */
  /* height: auto; */
  min-height: 200vh;
  max-height: 600vh;
  background: linear-gradient(to bottom, #7e98b9, #7e98b9);
  width: 100%;
  /* background-image: url('../Assets/background.png'); */
  background-size: cover;
  background-repeat: no-repeat;
  backdrop-filter: blur(20px);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.parent {
  height: auto; /* Set the container height to auto */
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto; /* Set rows to auto to adjust height based on content */
  grid-column-gap: 10vh;
  grid-row-gap: 10vh;
  padding: 10vh;
  width: 80%;
}
/*  */
.common {
  padding: 30px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
    rgba(0, 0, 0, 0.3) 0px 7px 13px -3px,
    rgba(255, 0, 0, 0.2) 0px -3px 0px inset;
  font-size: 1rem;
  letter-spacing: 1px;
  text-align: justify;
  font-weight: 500;
  background-color: #feecea;
  /* display: flex; */
  /* flex-direction: column; */
  /* align-items: center; */
}
.common img {
  width: 100px;
}

.all-benefits {
  width: 80%;
}

.div1 {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;

  .left-section {
    flex: 1;
    padding: 0 50px;
    text-align: justify;
    color: #ebf5fb;

    .left-header {
      text-align: left;
      /* width: 40%; */
      font-weight: 900;
      text-shadow: 1px 0 0 #3f5885;
      font-size: 2rem;
      margin-bottom: 10px;
      letter-spacing: 1px;
      color: F8E5E7;
      /* text-decoration: underline; */
    }
  }

  .left-section p {
    font-size: 1.1rem;
    letter-spacing: 0.5px;
  }
  /*  */
  .right-section {
    width: 30%;
  }

  .right-section img,
  .right-section-mobile img {
    height: 250px;

    border-radius: 200px;
    border: 20px solid #3f5885;
  }
}

.right-section-mobile {
  display: none;
}

/* .div1 {
  grid-area: 1 / 1 / 2 / 2;
}

.div2 {
  grid-area: 1 / 2 / 2 / 3;
}

.div3 {
  grid-area: 2 / 1 / 3 / 2;
}

.div4 {
  grid-area: 2 / 2 / 3 / 3;
} */

.benefit_plan_btn {
  /* align-self: flex-start; */
  font-size: 1.15rem;
  padding: 10px 30px;
  border-radius: 5px;
  border: none;
  background-color: #3f5885;
  margin-top: 10px;
  color: white;
  cursor: pointer;
  text-decoration: none;
}

/* 0 - 600px Screen Styles */
@media only screen and (max-width: 600px) {
  .right-section-mobile {
    display: block;
  }
  .div1 {
    flex-direction: column;

    .left-section {
      padding: 0;

      .left-header {
        font-size: 1.6rem;
        margin-bottom: 30px !important;
      }
    }

    .right-section-mobile {
      margin-top: 30px;
      margin-bottom: 10px;
    }

    .right-section {
      display: none;
      width: 100%;
    }
  }
}
