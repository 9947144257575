.DashBoardBar {
  height: 6.5vh;
  /* background-color: aqua; */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 10px;
  background-color: var(--background-color-500);
  margin-bottom: 5px;
  box-shadow: var(--shadow-primary);
}

.verticalDivider {
  height: 50%;
  padding: 0 1px;
  border-radius: 0.5px;
  /* width: 1.5px; */
  background-color: var(--border-color-500);
  margin: 0 5px;
}

.menu-ctr {
  color: var(--text-color-100);
  display: none;
  padding: 0 10px;
  height: 85%;
  border-radius: 5px;
  transition: all 0.1s ease-in;

  .menu-icon {
    font-size: 1.5rem;
  }
}

.searchtrade-ctr {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 1.1rem;
  /* padding: 1vh 10px; */
  transition: all 0.1s ease-in;
  border-radius: 5px;
  padding: 0 10px;
  height: 85%;

  .searchtrade-icon {
    /* color: var(--text-color-500); */
    color: var(--text-color-100);
    font-weight: bold;
    font-size: 1.2rem;
  }

  .searchtrade-name {
    margin: 0;
    font-weight: 600;
    margin-left: 5px;
    letter-spacing: 1px;
    color: var(--text-color-100);
  }

  &:hover {
    background-color: var(--background-color-900);
    cursor: pointer;
  }

  &:active {
    scale: 0.96;
  }
}

.avatar_centre {
  position: absolute;
  left: -15px;
  border: 2px solid var(--border-color-100);
  top: 50%;
  transform: translate(-50%, -50%);
  /* height: 90%; */
  /* width: 10% */
}

.logout {
  margin-left: auto;
  background-color: inherit;
  min-width: 400px;
  width: auto;
  max-width: 300px;
  color: var(--text-color-100);
  display: flex;
  justify-content: flex-end;
}

.mobilenav-ctr {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 100%;
    left: 0;
    width: 100%;
    height: 0;
    /* height: 100vh; */
    z-index: 2;
    color: var(--text-color-100);
    /* padding-bottom: 10px; */
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    z-index: 1000;
    background-color: var(--background-color-500);
    box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
    overflow: hidden;
    transition: height 0.2s ease-in-out;

    .searchtrade-name {
        text-align: center;
        font-size: 1.3rem;
        font-weight: 600;
        padding: 20px 0;
        margin: 0;
        height: 8.33vh;
        letter-spacing: 1px;
        color: var(--text-color-100);
        border-bottom: 1px solid;

        &:last-child {
            border: none;
            background-color: var(--background-color-100);
            color: var(--text-color-500);

        }
    }
}

.mobilenav-active {
    /* top: 100%; */
    height: 50vh;
}

.mobile-avatar {
  height: 40px;
  width: 50px;
  border-radius: 50%;
  border: 2px solid var(--border-color-100);
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;
  font-size: 1.2rem;
  background-color: var(--background-color-500);
  font-weight: 600;
}



/* Mobile Devices */
@media only screen and (max-width: 767px) {
  .mobile-avatar {
    display: flex;
  }
    .DashBoardBar {
        height: 8vh;
        padding: 0;
        position: relative;
    }
    .menu-ctr {
        display: flex;
        
        width: 100%;
    }

    .menu-icon {
        font-size: 2rem !important;
      }

    .searchtrade-ctr, .verticalDivider, .logout {
        display: none;
    }

    .searchtrade-name {
      color: var(--text-color-100) !important;

      &:last-child {
          color: var(--text-color-500) !important;
      }
    }

    
}

/* Tablets: */
@media only screen and (min-width: 768px) and (max-width: 991px) {
}

/* Small Laptops and Desktops */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
}

/* Standard Laptops and Desktops: */
@media only screen and (min-width: 1200px) {
}
